import React from "react";
import type { HeadFC } from "gatsby";
import { Kramer } from "../../screens/OverviewScreen";

const KramerBrandPage = () => {
    return <Kramer />;
};

export default KramerBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
